<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn 
        tile 
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary" 
        dark 
        class="mb-2 modal-cadastro-btn-cadastrar" 
        @click="openModal"
        :loading="loading">Novo cadastro</v-btn>
    </template>
    
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        <div class="abas" width="100%">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px">
            <v-tab href="#tab-1" class="abas-item">
              Cadastro
            </v-tab>

            <v-tab 
              href="#tab-2" 
              class="abas-item"
              :disabled="$v.usuario.$invalid || $v.radioButton.$invalid">
              Filtro
            </v-tab>

            <v-tab 
              href="#tab-3" 
              class="abas-item"
            >
              Promotores
            </v-tab>
          </v-tabs>
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :value="'tab-1'">
      
          <v-form>
            <v-card-title v-if="editUsuario === false" class="modal-cadastro-titulo">
              Cadastre o novo usuário
            </v-card-title>
            <v-card-title v-else class="modal-cadastro-titulo">
              Editar usuário
            </v-card-title>

            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="nameErrors" v-model="usuario.name" label="Nome completo:" @input="$v.usuario.name.$touch()" @blur="$v.usuario.name.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="usernameErrors" v-model="usuario.username" label="Username:"  @input="$v.usuario.username.$touch()" @blur="$v.usuario.username.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field :error-messages="emailErrors" v-model="usuario.email" label="Email:"  @input="$v.usuario.email.$touch()" @blur="$v.usuario.email.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="tel" :error-messages="phoneErrors" v-model="usuario.phone" label="Telefone:"  @input="$v.usuario.phone.$touch()" @blur="$v.usuario.phone.$touch()" v-mask="['(##) ####-####', '(##) #####-####']" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="password" :error-messages="senhaErrors" v-model="usuario.senha" label="Senha:"  @input="$v.usuario.senha.$touch()" @blur="$v.usuario.senha.$touch()" />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-text-field type="password" :error-messages="confirmaSenhaErrors" v-model="usuario.confirmaSenha" label="Confirmar senha:"  @input="$v.usuario.confirmaSenha.$touch()" @blur="$v.usuario.confirmaSenha.$touch()" />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
                  <v-checkbox
                    v-model="usuario.jsonFlags.cashInAllEntities"
                    label="Ver todas as entidades no pré-caixa"
                  ></v-checkbox>
                </v-col>
              </v-row>
              
              <!-- Roles -->
              <v-row>
                <v-col cols="12" class="roles">
                  <v-card-title class="modal-cadastro-titulo roles-title">
                      Grupo de Permissões
                  </v-card-title>
                  <div ref="errorCard" class="val">
                    <template v-for="itens in select">
                      <div :class="{'roles-borda': true, 'roles-active': radioButton[0] === itens.id}" :key="itens.id + 'card-borda'">
                        <v-row :key="itens.id + 'card'">
                          <v-col cols="8" class="roles-name"> {{  itens.name }} </v-col>
                          <v-col cols="4" class="roles-div">
                            <span :class="{'roles-icon': true, 'role-icon-active': itens.id === radioButton[0] }" @click="visibleFilhos(itens)">
                              <v-icon v-if="visible === itens.id && itens.parent.length !== 0">keyboard_arrow_up</v-icon>
                              <v-icon v-else> keyboard_arrow_down</v-icon>
                            </span>
                            <span class="roles-checkbox">
                              <v-checkbox color="success" v-model="radioButton" @click="addCheckbox(itens)" :name="`role${itens.id}`" :value="itens.id" />
                            </span>
                          </v-col>
                        </v-row>
                        <template v-for="val in itens.parent">
                          <div :key="val.id" class="roles-sub-itens">
                            <v-row v-if="parseInt(visible) ===  parseInt(val.parent_id)">
                              <v-col class="roles-lista-default">
                                <div class="roles-lista">
                                  <v-col class="roles-lista-default"> <label> {{ val.name}} </label> </v-col>
                                  <v-col class="roles-lista-default"> <v-checkbox class="roles-lista-check-sub" v-model="radioButton" @click="addCheckbox(val)" :name="`role${val.id}`" :value="val.id"  /> </v-col>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </v-col>
                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4"><v-select :items="itensStatusUsuario" v-model="usuario.statusUsuario" label="Situação"  @input="$v.usuario.statusUsuario.$touch()" @blur="$v.usuario.statusUsuario.$touch()" /></v-col> -->
                <!-- <v-col cols="6"><v-switch v-model="usuario.statusUsuario" inset label="Status do usuário"></v-switch></v-col> -->
              </v-row>
            </v-card-text>
            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="proximo" class="br-btn" :loading="loading">Continuar</v-btn>
              <!-- <v-btn type="submit" :color="variables.colorPrimary" v-else @click.prevent="submit" class="br-btn" :loading="loading">Salvar</v-btn> -->
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item
          :value="'tab-2'">

          <v-form>
            <v-card-title class="modal-cadastro-titulo">
              Filtrar entidades
            </v-card-title>
          
            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="userEntityIds"
                    :items="listSelectEntitiesWithSelected"
                    chips
                    label="Selecione uma ou mais entidades"
                    multiple
                    v-on:keyup="searchAutoComplete"
                    :search-input.sync="searchText"
                    :loading="isLoadingAutoComplete"
                    return-object
                    :error-messages="filterEntitidadesErrors"
                    @input="$v.userEntityIds.$touch()"
                    dense
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeEntities(data.item)">
                        {{ data.item.text }}
                      </v-chip>
                    </template>

                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.labelComplet"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary"  @click.prevent="submit" class="br-btn" :loading="loading">{{ editUsuario ? 'Salvar' : 'Cadastrar' }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item
          :value="'tab-3'">

          <promotores @cadastrar="cadastrar" :entityId="entityPromotorId" />
        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { size, filter } from 'lodash'
import { formatPhone } from '@/core/service/utils'

import validate from './validateComputed'

const contains = (params) => (value) => {
  if (params.userEntityIds.length === 0) return true

  if (value.length > 0 && params.userEntityIds) {
    return params.userEntityIds.includes(value[0].value) ? !!1 : !!0
  }

  return false
}

export default {
  name: 'BrModalCadastroUsuario',
  components: {
    promotores: () => import('./promotores')
  },
  mixins: [validationMixin],
  directives: {
    mask
  },
  data: () => ({
    count: 0,
    visible: false,
    radioButton: [],
    user: null,
    activeModal: false,
    tab: 'tab-1',
    loading: false,
    isLoadingAutoComplete: false,
    searchText: null,
    userEntityIds: [],
    debounce: null,
    entityPromotorId: undefined
  }),
  
  watch: {
    tab (val) {
      if (val === 'tab-2') {
        this.searchText = null     
        this.searchAutoComplete()
      }
    },
    // searchAutoComplete (val) {
    //   if (val === null) return
    //   if (val.length < 3) return
      
    //   if (this.isLoadingAutoComplete)  return

    //   this.isLoadingAutoComplete = true

      
    // },

    'usuario.id' (val) {
      if (val) {
        this.openModal()
      }
    }
  },

  mounted () {
    const self = this
    Events.$on('cadastro::openModalCadastro', (e) => {
      self.radioButton.push(String(e.roleId))
      self.userEntityIds = []
      if (e.userEntities.length > 0) {
        e.userEntities.forEach (el => {
          self.userEntityIds.push({ text: el.cod, value: el.id })
        })
      }

      if (e.jsonFlags.responsibleForPromoterEntityId) {
        self.entityPromotorId = e.jsonFlags.responsibleForPromoterEntityId
      } else {
        self.entityPromotorId = null
      }
    })
  },
  computed: {
    ...mapGetters('auth', {
      dadosUserLogado: 'user'
    }),
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('entities', {
      listSelectEntidades: 'listaItensSelectUser'
    }),
    ...mapState({
      select (state) {
        return state.roles.lista
      }
    }),
    ...mapGetters('users', {
      usuario: 'getItem',
      userEntities: 'listUserEntities'
    }),
    
    ...validate,

    editUsuario () {
      return this.usuario.id ? !!1 : !!0
    },

    listSelectEntitiesWithSelected () {
      return this.userEntityIds.length > 0 ? this.listSelectEntidades.concat(this.userEntityIds) : this.listSelectEntidades
    },
    
    variables: () => variables,
  },
  methods: {
    ...mapActions('roles', ['buscarItem']),
    ...mapActions('users', ['cadastrarItem', 'limparItem', 'editarItem', 'getItens', 'cadastrarUserEntities', 'getItemUsersEntities']),
    ...mapActions('entities', {
      getItensEntities: 'getListTree'
    }),

    searchAutoComplete() {
      // debounce(this.search, 1000)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (!size(this.searchText)) {
          this.search()
          return
        }

        this.search()
      }, 200)
    },

    search () {
      this.isLoadingAutoComplete = true
      // this.getItensEntities({ _filter: { 'jsonData_->_name_like':`%${val}%`, 'entityTypeId_In':'1,2,3,4,5' } })
      let filter = { _filter: { 'Entities:jsonData.cod_ilike':`${this.searchText}%`, 'entityTypeId_In':'2,3,4,5,7' }, order: {'cod::int': 'ASC'} }
      if (!size(this.searchText))  {
        filter = { _filter: { 'Entities:jsonData.cod_ilike':`${1}%`, 'entityTypeId_In':'2,3,4,5,7' }, order: {'cod::int': 'ASC'} }
      }
      this.getItensEntities(filter)
        .catch(err => {
          Events.$emit('snackbarCadastro::msg', {
            toggle: true,
            type: 'error',
            msg: err.error
          })
        })
        .finally(() => this.isLoadingAutoComplete = false)
    },

    visibleFilhos (val) {
      if (val.id == this.visible) {
        return this.visible = false
      }
      this.visible = val.id 
    },
    addCheckbox (val) {
      if (size(this.radioButton) === 0) {
        return this.radioButton.push(val.id)
      }
      this.radioButton.pop()
      this.radioButton.push(val.id)
    },
    remove (val) {
      const index = this.usuario.roles.indexOf(val.name)
      if (index >= 0) this.usuario.roles.splice(index, 1)
    },

    removeEntities (item) {
      // const index = this.userEntityIds.value.indexOf(item.value)
      // if (index >= 0) this.userEntityIds.splice(index, 1)
      this.userEntityIds = filter(this.userEntityIds, itemFilter => {
        return itemFilter.value !== item.value
      })
    },
    
    async openModal () {
      this.loading = true
      await Promise.all([
        this.buscarItem(),
        this.getItensEntities({
          pageSize: this.$store.getters.ItensPaginacao,
          _filter: { "entityTypeId_In":"2,3,4,5,7" }
        }),
        this.getItemUsersEntities()
      ])
      .then(() => {
        this.activeModal = true
      })
      .catch(e => {
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: e.error
        })
      })
      .finally(() => {
        this.loading = false
      })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.userEntities = []
      this.tab = 'tab-1'
      this.$v.usuario.$reset()
      this.radioButton = []
    },
    errorPermissoes () {
      if (this.$v.radioButton.$invalid) {
        this.$refs.errorCard.classList.add('roles-error')
        return true
      } 
      this.$refs.errorCard.classList.remove('roles-error')
    },


    proximo () {
      if (this.$v.usuario.$invalid || this.errorPermissoes() === true) {
        this.$v.usuario.$touch()
        this.tab = 'tab-1'
        return false 
      }

      this.tab = 'tab-2'
    },

    cadastrar (promotor) {
      if (promotor && promotor.value) {
        this.usuario.jsonFlags.responsibleForPromoterEntityId = promotor.value
      } else {
        this.usuario.jsonFlags.responsibleForPromoterEntityId = undefined
      }
      this.tab = 'tab-1'
      this.submit()
    },

    submit () {
      const self = this

      if (self.$v.usuario.$invalid || self.errorPermissoes() === true) {
        self.$v.usuario.$touch()
        self.tab = 'tab-1'
        return false 
      }

      if (!self.usuario.id && self.$v.userEntityIds.$invalid) {
        self.$v.userEntityIds.$touch()
        return false
      }

      this.loading = true

      let userEntityIds = []
      self.userEntityIds.forEach(el => {
        userEntityIds.push(el.value)
      });

      const dados = {
        name: self.usuario.name,
        username: self.usuario.username.toLowerCase(),
        email: self.usuario.email.toLowerCase(),
        phone: formatPhone(self.usuario.phone),
        roleId: parseInt(self.radioButton[0]),
        userEntityIds: userEntityIds,
        jsonFlags: {
          cashInAllEntities: self.usuario.jsonFlags.cashInAllEntities,
          responsibleForPromoterEntityId: self.usuario.jsonFlags.responsibleForPromoterEntityId
        }
      }

      if (self.usuario.id && self.usuario.id !== null && self.usuario.id !== '') {
        // return assignIn({ id: self.usuario.id }, dados)
        dados.id = self.usuario.id
      }

      if (self.usuario.senha) {
        dados.password = self.usuario.senha
      }

      //salva os dados ao editar
      if (self.editUsuario === true) {
        return self.usuarioEditSave(dados)
      }
     
      self.cadastroUsuario(dados)
    },

    usuarioEditSave (val) {
      const self = this
      self.editarItem(val).then(() => {
        self.closeModal()
        self.loading = false
        self.getItens()
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'success',
          msg: 'Edição realizado com sucesso!'
        })
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    },

    validacaoCadastroUsuario () {
      const self = this
      if (self.$v.usuario.$invalid || self.errorPermissoes() === true) {
        self.$v.usuario.$touch()
        self.tab = 'tab-1'
        return false 
      }
    },

    cadastroUsuario (dados) {
      const self = this

      self.cadastrarItem(dados).then(() => {
        self.loading = false
        self.getItens()
        self.closeModal()
        const msg = {
          toggle: true,
          type: 'success',
          msg: 'Cadastro realizado com sucesso!'
        }
        Events.$emit('snackbarCadastro::msg', msg)
      }).catch(err => {
        self.loading = false
        Events.$emit('snackbarCadastro::msg', {
          toggle: true,
          type: 'error',
          msg: err.error
        })
      })
    },

    // cadadostroEntities (userEntities, userId) {
    //   const _ = this
    //   const dados = []

    //   forEach(userEntities, item => {
    //     dados.push({ userId: userId, entityId: item })
    //   })

    //   _.cadastrarUserEntities(dados).then(() => {
    //     _.closeModal()
    //     _.loading = false
    //     _.getItens()
    //     Events.$emit('snackbarCadastro::msg', {
    //       toggle: true,
    //       type: 'success',
    //       msg: 'Cadastro realizado com sucesso!'
    //     })
    //   }).catch(err => {
    //     _.loading = false
    //     Events.$emit('snackbarCadastro::msg', {
    //       toggle: true,
    //       type: 'error',
    //       msg: err.error
    //     })
    //   })
    // },

    // submitUserEntities () {
    //   const _ = this
    //   if (!size(_.userEntities)) {
    //     return false
    //   }

    //   if (_.usuario.id) {
    //     _.cadadostroEntities(_.userEntities, _.usuario.id)
    //   } else {
    //     if (_.$v.usuario.$invalid || _.errorPermissoes() === true) {
    //     _.$v.usuario.$touch()
    //     _.tab = 'tab-1'
    //     return false 
    //   }

    //     _.cadastroUsuario(_.usuario).then(result => {
    //       _.cadadostroEntities(_.userEntities, result.data.id)
    //     })
    //   }
    // }
  },

  validations () {
    const _ = this
    let args = {
      usuario: {
        name: { required },
        username: { required },
        phone: { required, minLength: minLength(15) },
        senha: {
          required
        },
        confirmaSenha: { 
          required,
          sameAsPassword: sameAs('senha')
        },
        email: { required, email },
      },
      radioButton: {
        required
      },

      userEntityIds: {
        validateUserEntityIds: contains(this.dadosUserLogado)
      }
    }
    if (_.editUsuario === true) {
      args.usuario.senha = {}
      args.usuario.confirmaSenha = {}
    }
    if (_.usuario.senha !== undefined) {
        args.usuario.senha = { required}
        args.usuario.confirmaSenha = { required, sameAsPassword: sameAs('senha')}
    }

    return args
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/modal-cadastro';
  @import '../../../../../assets/styles/components/formularios';
</style>