export default {
  nameErrors () {
    const errors = []
    if (!this.$v.usuario.name.$dirty) return errors
    !this.$v.usuario.name.required && errors.push('Nome é obrigatório.')
    return errors
  },
  
  usernameErrors () {
    const errors = []
    if (!this.$v.usuario.username.$dirty) return errors
    !this.$v.usuario.username.required && errors.push('Login é obrigatório.')
    return errors
  },
  
  emailErrors () {
    const errors = []
    if (!this.$v.usuario.email.$dirty) return errors
    !this.$v.usuario.email.email && errors.push('Email invalido!')
    !this.$v.usuario.email.required && errors.push('Email é obrigatório!')
    return errors
  },
  
  confirmaSenhaErrors () {
    const errors = []
    if (!this.$v.usuario.confirmaSenha.$dirty) return errors
    !this.$v.usuario.confirmaSenha.required && errors.push('Confirmar senha é obrigatório.')
    !this.$v.usuario.confirmaSenha.sameAsPassword && errors.push('Senhas não conferem!.')
    return errors
  },
  
  senhaErrors () {
    const errors = []
    if (!this.$v.usuario.senha.$dirty) return errors
    !this.$v.usuario.senha.required && errors.push('Confirmar senha é obrigatório.')
    return errors
  },
  
  phoneErrors () {
    const errors = []
    if (!this.$v.usuario.phone.$dirty) return errors
    !this.$v.usuario.phone.required && errors.push('Campo telefone é obrigatório.')
    !this.$v.usuario.phone.minLength && errors.push('Campo telefone inválido.')
    return errors
  },

  filterEntitidadesErrors () {
    const errors = []
    if (!this.$v.userEntityIds.$dirty) return errors
    // !this.$v.userEntityIds.required && errors.push('Por favor, selecionar pelo menos uma entidade.')
    !this.$v.userEntityIds.validateUserEntityIds && errors.push('Por favor, selecionar pelo menos uma entidade.')
    return errors
  }
}